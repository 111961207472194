import { format } from 'date-fns';
import * as crypto from 'crypto';

const MEMBER_NO = '79'

const getTrUniqNo = (): string => {
  return ('' + +new Date()).substr(1)
}

export const getApprovalId = (): string => {
  return crypto.createHash('md5').update(('' + +new Date())).digest('hex').substr(0, 8)
}

export const getRequestHeader = (jnmnCd: string, strtChar: string): CardRequestHeader => {
  return {
    trCd: "12345",
    strtChar,
    jnmnCd,
    trUniqNo: getTrUniqNo(),
    jnmnTrmsDt: format(new Date(), 'yyyyMMdd'),
    rspnCd: "00",
    mbNo: MEMBER_NO,
    reserve: "",
    inqryMbNo: ""
  }
}