import client from './client';
import { getRequestHeader, getApprovalId } from './common'
import { get, find } from 'lodash';
import { format } from 'date-fns';
import merchants from '../../views/MerchantList/merchants'

export default async (input: ApproveInput, cardNumber: string): Promise<BC6521BodyVO> => {
  const totalAmount = Number(input.netAmount) + Number(input.taxAmount) + Number(input.serviceAmount);
  if (totalAmount < 100) {
    throw new Error(`승인 실패 - 승인 최소 금액은 100원 입니다.`)
  }
  console.log('1100', getBody(input, cardNumber))
  return client.post(`/root/cardApvl`, getBody(input, cardNumber)).then(res => {
    const statusCode = get(res, 'data.header.rspnCd', undefined);
    console.log(statusCode, res)
    if (!statusCode || statusCode !== '01') {
      throw new Error(`승인 실패`)
    }

    return res.data.body;
  }).catch(e => {
    throw e 
  });
}

const getBody = (input: ApproveInput, cardNumber: string): BC1100VO => {
  return {
    header: getRequestHeader('1100', 'BCK'),
    body: {
      aprvProcCd: '020000',
      aprvReqTotalAmt: input.totalAmount ? input.totalAmount :
        '' + (Number(input.netAmount) + Number(input.taxAmount) + Number(input.serviceAmount)),
      netAmt: input.netAmount,
      taxAmt: input.taxAmount,
      serviceAmt: input.serviceAmount,
      msgStartDttm: format(new Date(), 'yyyyMMddHHmmss'),
      selKndCd: '05',
      installmentMm: '0',
      cardNo: cardNumber,
      merchantNo: input.merchantId,
      merchantName: input.merchantName ? input.merchantName :
        getMerchantName(input.merchantId),
      apprNo: getApprovalId()
    }
  }
}

const getMerchantName = (merchantId: string) => {
  const matchedMerchant =
    find(merchants, merchant => { return merchant.id === merchantId});
  if (matchedMerchant) {
    return matchedMerchant.name;
  }

  return merchantId;
}