import React from 'react';
import { Button, Theme, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { SearchInput } from '../../components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const UsersToolbar = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          component={Link}
          color="primary"
          variant="contained"
          to="/addcard"
        >
          Add card 
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Card number"
        />
      </div>
    </div>
  );
};

export default UsersToolbar;
