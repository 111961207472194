export default [
  {
    id: '1234-5677',
    name: '씨유',
    sector: '편의점'
  },
  {
    id: '1234-5678',
    name: '성수감자탕',
    sector: '식당'
  },
  {
    id: '1234-5679',
    name: '언커먼그라운드',
    sector: '까페'
  },
];
