import React, { FC, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: 0
  },
  dialogContent: {
    minWidth: '400px',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  isFinalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  title: string,
  cards: CardInfo[],
}
const CardList: FC<Props> = ({
  title,
  cards,
}) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Card>
      { !!title &&
        <CardHeader title={title}/>
      }
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>카드번호</TableCell>
                  <TableCell>상품명</TableCell>
                  <TableCell>유효기한</TableCell>
                  <TableCell>등록일자</TableCell>
                  <TableCell>최종카드여부</TableCell>
                  <TableCell>상태</TableCell>
                  <TableCell>사고등재여부</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cards.slice(0, rowsPerPage).map(card => (
                  <TableRow
                    hover
                    key={card.cardNo}
                  >
                    <TableCell>
                      <Link to={{ pathname: `/carddetail/${card.cardNo}` }}>{card.cardNo}</Link>
                    </TableCell>
                    <TableCell>{card.prdtName}</TableCell>
                    <TableCell>{card.valdTermYm}</TableCell>
                    <TableCell>{card.isucDt}</TableCell>
                    <TableCell>
                      <div className={classes.isFinalContainer}>
                        {card.lastCardYn}
                      </div>
                    </TableCell>
                    <TableCell>{card.cardStat}</TableCell>
                    <TableCell>{card.acdtStatDvsnCd}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={cards.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default CardList;
