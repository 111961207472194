import React, { useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import CardListToolbar from './CardListToolbar';
import CardTable from './CardTable';

import mockData from './data';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();

  const [cards] = useState(mockData);

  return (
    <div className={classes.root}>
      <CardListToolbar />
      <div className={classes.content}>
        <CardTable cards={cards} />
      </div>
    </div>
  );
};

export default UserList;
