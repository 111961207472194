import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    cardNumber: '9411-1234-5678-0001',
    prodName: 'CHAI국내카드',
    expirationDate: 1555016400000,
    registrationDate: 1555016400000,
    isFinalCard: 'Y',
    status: 'enabled',
    event: '',
  },
  {
    id: uuid(),
    cardNumber: '9411-1234-5678-0002',
    prodName: 'CHAI국내카드',
    expirationDate: 1555016400000,
    registrationDate: 1555016400000,
    isFinalCard: 'Y',
    status: 'enabled',
    event: '',
  },
  {
    id: uuid(),
    cardNumber: '9411-1234-5678-0003',
    prodName: 'CHAI국내카드',
    expirationDate: 1555016400000,
    registrationDate: 1555016400000,
    isFinalCard: 'Y',
    status: 'terminated',
    event: '',
  },
];
