import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { find } from 'lodash';
import { parse, format } from 'date-fns'
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TablePagination,
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

import { CardDialog } from '../../components'
import { cancelApproval } from '../../lib/cardApi'
import useStyles from './styles';

type Props = {
  className?: string,
  approvals: GetApprovalListReturn[],
  cardInfo: CardInfo,
  refreshApprovals: (cardNumber: string) => Promise<void>
}
const ApprovalList: FC<Props> = ({ className, approvals, cardInfo, refreshApprovals}) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [cancelTarget, setCancelTarget] = useState<GetApprovalListReturn>();
  const [cancelApprovalOpen, setCancelApprovalOpen] = useState(false);

  const onCancelBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const cardApvlNo = event.currentTarget.value;
    const targetApproval = find(approvals, approval => { return approval.cardApvlNo === cardApvlNo});
    setCancelTarget(targetApproval);
    setCancelApprovalOpen(true);
  };

  const onCancelConfirm = async () => {
    if (!cancelTarget) {
      return;
    }

    try {
      const result = await cancelApproval(cancelTarget);
      console.log(result);

      await refreshApprovals(cancelTarget.cardNo);
      setCancelApprovalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  const isCancelable = (cardApvlNo: string) => {
    const cancelExist = find(approvals, approval => {
      return approval.cardApvlNo === cardApvlNo && approval.rvnuKindDvsnCd === '15'
    })
    return cardInfo.cardStat === '정상' && cardInfo.lastCardYn === 'Y' && !cancelExist
  }

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="승인 내역"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>카드승인번호</TableCell>
                  <TableCell>차이승인번호</TableCell>
                  <TableCell>승인일시</TableCell>
                  <TableCell>카드번호</TableCell>
                  <TableCell>가맹점명</TableCell>
                  <TableCell>승인/취소</TableCell>
                  <TableCell>승인금액</TableCell>
                  <TableCell>처리결과</TableCell>
                  <TableCell>승인취소</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {approvals.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map(approval => (
                  <TableRow
                    hover
                    key={approval.cardApvlNo + approval.rvnuKindDvsnCd}
                  >
                    <TableCell>{approval.cardApvlNo}</TableCell>
                    <TableCell>{approval.paymentId}</TableCell>
                    <TableCell>{format(
                      parse(approval.oprtDtlDtime, 'yyyyMMddHHmmss', new Date()),
                      'yyyy/MM/dd HH:mm:ss')}</TableCell>
                    <TableCell>{approval.cardNo}</TableCell>
                    <TableCell>{approval.merchantName}</TableCell>
                    <TableCell>{(approval.rvnuKindDvsnCd === '05') ? '승인' : '취소'}</TableCell>
                    <TableCell>{approval.cardApvlAmt}</TableCell>
                    <TableCell>{(approval.apvlPrcsStatCd === '1') ? '정상' : '오류'}</TableCell>
                    <TableCell>
                      { approval.rvnuKindDvsnCd === '05' && approval.apvlPrcsStatCd === '1'  && isCancelable(approval.cardApvlNo) &&
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={onCancelBtnClick}
                        value={approval.cardApvlNo}
                      >
                        <div className={classes.icon}>
                          <Cancel/>
                        </div>
                        취소  
                      </Button>

                      }
                  </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={approvals.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      <CardDialog
        title="승인취소"
        context="승인취소 하시겠습니까?"
        open={cancelApprovalOpen}
        setOpen={setCancelApprovalOpen}
        onConfirm={onCancelConfirm}
      />
    </Card>
  );
};

export default ApprovalList;
