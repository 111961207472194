import client from './client';
import { get, orderBy } from 'lodash';

export default async (input: GetAccidentListInput): Promise<GetAccidentListReturn[]> => {
  return client.post(`/root/cardAcdt/acdtListSelect`, input).then(res => {
    console.log(input)
    console.log(res)
    const accidents = get(res, 'data.AcdtHis');
    if (!accidents) {
      throw new Error(`사고정보 조회 실패`)
    }
    console.log(accidents)
    return orderBy(accidents, ['date', 'time'], ['desc', 'desc']);
  }).catch(e => {
    console.log(e)
    throw e 
  });
}