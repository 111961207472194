import client from './client';
import { getRequestHeader } from './common'
import { get, find } from 'lodash';
import { format } from 'date-fns';
import merchants from '../../views/MerchantList/merchants'

export default async (input: GetApprovalListReturn): Promise<BC6521BodyVO> => {
  console.log('1420', getBody(input))
  return client.post(`/root/cardApvlCncl`, getBody(input)).then(res => {
    const statusCode = get(res, 'data.header.rspnCd', undefined);
    console.log(statusCode, res)
    if (!statusCode || statusCode !== '02') {
      throw new Error(`승인 취소 실패`)
    }

    return res.data.body;
  }).catch(e => {
    throw e 
  });
}

const getBody = (input: GetApprovalListReturn): BC1100VO => {
  return {
    header: getRequestHeader('1420', 'BCK'),
    body: {
      aprvProcCd: '012000',
      aprvReqTotalAmt: input.cardApvlAmt ? input.cardApvlAmt :
        '' + (Number(input.rvnuAmt) + Number(input.taxAmt) + Number(input.svcFee)),
      netAmt: input.rvnuAmt,
      taxAmt: input.taxAmt,
      serviceAmt: input.svcFee,
      msgStartDttm: format(new Date(), 'yyyyMMddHHmmss'),
      selKndCd: '15',
      installmentMm: '0',
      cardNo: input.cardNo,
      merchantNo: input.merchantNo,
      merchantName: input.merchantName ? input.merchantName :
        getMerchantName(input.merchantNo),
      apprNo: input.cardApvlNo 
    }
  }
}

const getMerchantName = (merchantId: string) => {
  const matchedMerchant =
    find(merchants, merchant => { return merchant.id === merchantId});
  if (matchedMerchant) {
    return matchedMerchant.name;
  }

  return merchantId;
}